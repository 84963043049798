.home_artists {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	$c: &;
	&-item {
		width: calc(50%-20px);
		height: 230px;
		margin-bottom: 40px;
		background: #2e2e2e;
		&:hover {
			// cursor: default;	//暫定処理

			#{$c}-thumb:before {
				opacity: 0.5;
			}
			#{$c}-thumb:after {
				background: resolve("home_artists_x_white.png") center no-repeat;
				background-size: size("home_artists_x_white.png", 2);
				width: width("home_artists_x_white.png", 2);
				height: height("home_artists_x_white.png", 2);
			}
			#{$c}-title {
				opacity: 1;
			}
		}
	}
	&-thumb {
		height: 230px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		display: flex;
		align-items: flex-end;
		transition: 0.3s;
		position: relative;
		&:before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background: #000;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			transition: 0.3s;
		}
		&:after {
			content: "";
			display: block;
			background: resolve("home_artists_x_black.png") center no-repeat;
			background-size: size("home_artists_x_black.png", 2);
			width: width("home_artists_x_black.png", 2);
			height: height("home_artists_x_black.png", 2);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: 0.3s;
		}
	}
	&-title {
		width: 50%;
		color: #fff;
		opacity: 0;
		transition: 0.3s;
		position: relative;
		z-index: 2;
		padding: 11px 15px;
	}
	&-name {
		font-size: 15px;
		font-weight: 700;
	}
	&-name-en {
		font-size: 15px;
		@extend .font-heebo;
	}
}
@include breakpoint(sp) {
	.home_artists {
		flex-direction: column;
		$c: &;
		&-item {
			width: 100%;
			height: auto;
			margin-bottom: 40px;
			&:hover {
				#{$c}-thumb:before {
					opacity: 0.5;
				}
				#{$c}-thumb:after {
					background: resolve("home_artists_x_white.png") center
						no-repeat;
					background-size: size("home_artists_x_white.png", 4);
					width: width("home_artists_x_white.png", 4);
					height: height("home_artists_x_white.png", 4);
				}
				#{$c}-title {
					opacity: 1;
				}
			}
		}
		&-thumb {
			height: 32vw;
			&:before {
				opacity: 0.5;
			}
			&:after {
				background: resolve("home_artists_x_white.png") center no-repeat;
				background-size: size("home_artists_x_white.png", 4);
				width: width("home_artists_x_white.png", 4);
				height: height("home_artists_x_white.png", 4);
			}
		}
		&-title {
			opacity: 1;
			padding: 7px 10px;
		}
		&-name {
			font-size: 14px;
		}
		&-name-en {
			font-size: 14px;
		}
	}
}
@include breakpoint(exsp) {
	.home_artists {
		&-name {
			font-size: 13px;
			line-height: 1.4;
		}
		&-name-en {
			font-size: 13px;
			line-height: 1.4;
		}
	}
}
