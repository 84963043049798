.artist_header {
	display: flex;
	justify-content:center;
	$c:&;
	margin-bottom: 65px;
	&-symbol{
		background: resolve("icon_x_white.png") center no-repeat;
		background-size: size("icon_x_white.png",2);
		width: width("icon_x_white.png",2);
		height: height("icon_x_white.png",2);
		margin :12px 25px 0;
	}
	&-title{
		width: 50%;
		flex: 1;
		&:first-child{
			text-align: right;
		}
		&-inner{
			display: inline-block;
			text-align: left;
		}
	}
	&-name{
		display: inline-block;
		>*{
			display: inline;
			text-align: left;
			position: relative;
		}
		&-jp-left{
			font-size: 27px;
			font-weight: bold;
			position: relative;
			font-weight: 700;
			line-height: 1;
			&.mod-2rows{
				display: inline-block;
			}
			// &:after{
			// 	content:"";
			// 	display:inline-block;
			// 	height: 28px;
			// 	border-right: 1px solid #fff;
			// 	margin:0 8px 0 10px;
			// 	position: relative;
			// 	top:2px;
			// }
		}
		&-en-left{
			@extend .font-heebo;
			font-size: 16px;
			font-weight: 500;
			line-height: 1;
			top:-4px;
			&:before{
				content:"";
				display:inline-block;
				height: 26px;
				border-right: 1px solid #fff;
				margin:0 10px 0 9px;
				position: relative;
				top:6px;
			}
			&.mod-2rows{
				display: inline-block;
				&:before{
					margin-left: 3px;
				}				
			}
		}

		&-en-right{
			@extend .font-heebo;
			font-size: 30px;
			// font-weight: 900;
			font-weight: 500;
			line-height: 1;
			&.mod-2rows{
				display: inline-block;
			}
			// &:after{
			// 	content:"";
			// 	display:inline-block;
			// 	height: 28px;
			// 	border-right: 1px solid #fff;
			// 	margin:0 8px 0 10px;
			// 	position: relative;
			// 	top:1px;
			// }
		}
		&-jp-right{
			font-size: 15px;
			font-weight: bold;
			line-height: 1;
			top:-4px;
			&:before{
				content:"";
				display:inline-block;
				height: 26px;
				border-right: 1px solid #fff;
				margin:0 10px 0 9px;
				position: relative;
				top:6px;
			}
			&.mod-2rows{
				display: inline-block;
				&:before{
					margin-left: 3px;
				}				
			}
		}
	}
	&-role{
		padding-top: 7px;
		>*{
			display: inline;
			text-align: left;
		}
		&-jp-left,
		&-jp-right{
			font-size: 13px;
			font-weight: 700;
			display: inline-block;
		}

		&-en-left,
		&-en-right{
			@extend .font-heebo;
			font-weight: 500;
			font-size: 14px;
			display: inline-block;
		}
		&-jp-left{
			&:after{
				content:"";
				display:inline-block;
				height: 12px;
				border-right: 1px solid #fff;
				margin:0 6px 0 10px;
				position: relative;
				top:1px;
			}
		}
		&-en-right{
			&:after{
				content:"";
				display:inline-block;
				height: 12px;
				border-right: 1px solid #fff;
				margin:0 6px 0 10px;
				position: relative;
				top:1px;
			}
		}
	}
}
@include breakpoint(sp) {
	.artist_header {
		align-items: flex-start;
		flex-direction: column;
		$c:&;
		&-title{
			width:  auto;
		}
		&-symbol{
			margin:20px;
		}
		&-name{
			>*{
				display: block;
				&:after{
					display: none;
				}
				&.mod-2rows{
					display: block;
				}
			}
			&-jp-right{
				padding-top: 5px;
				top:auto;
				&:before{
					margin-left: 3px;
				}
			}

			&-en-left{
				padding-top: 5px;
				top:auto;
				&:before{
					margin-left: 3px;
				}
			}


		}
		&-role{
			padding-top: 10px;
			>*{
				// display: block;
				&:after{
					// display: none;
				}
			}
		}
	}
}
