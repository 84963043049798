.row {
	display: flex;
  justify-content:space-between;
  flex-wrap: wrap;
	margin :0 auto;
	& + &{
		margin-top: 30px;
	}
	&-col{
		&.mod-2col{
			width:46%;
      // max-width: 500px;
      
      &:nth-of-type(n + 3){
        margin-top: 60px;
      }
		}
		&.mod-1col{
			width: 100%;
		}
	}
}
@include breakpoint(sp) {
	.row {
		flex-direction: column;
		& + &{
			margin-top: 60px;
		}
		&-col{
			&.mod-2col{
				width:100%;
				max-width: 100%;
			}
			 & + &{
			 	margin-top: 60px;
			 }
		}
	}
}
