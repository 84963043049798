//行ごとにする場合
.js-inview-slide-oneline{
	transform:translate(30px,0);
	opacity:0;
	transition:0.3s;
	display: inline-block;
	&.is-show{
		transform:translate(0,0);
		opacity:1;
	}
}

//下層テキスト
.js-inview-slide-text{
	transform:translate(20px,0);
	opacity:0;
	transition:0.5s;
	// display: inline-block;
	&.is-show{
		transform:translate(0,0);
		opacity:1;
	}
}
.js-inview-border {
	&.is-show:after{
		transition:0.3s;
		transition-delay:0.2s;
		width: 100%;
	}
}
.js-inview-slideup {
	opacity:0;
	transform:translate(0 ,30px);
	transition:0.5s;
	transition-delay:0.2s;
	&.is-show{
		opacity:1;
		transform:translate(0 ,0);
	}
}
.js-inview-show{
	opacity:0;
	transition:0.5s;
	transition-delay:0.4s;
	&.is-show{
		opacity:1;
	}
}
.js-inview-title{
	opacity: 0;
	&.is-show{
		opacity:1;
	}
	span{
		opacity: 0;
		transition:0.6s cubic-bezier(.65,.09,.28,1);
		transform:translate(0,40%);
		display: inline-block;
		&.is-show{
			opacity:1;
			transform:translate(0,0);
		}
	}
}
@include breakpoint(sp) {
	.js-inview-slide-oneline{
		transform:translate(0,0);
		opacity:1;
		transition:0;
		display: inline;
	}
	.js-inview-slide-paragraph{
		transform:translate(30px,0);
		opacity:0;
		transition:0.5s;
		display: inline-block;
		&.is-show{
			transform:translate(0,0);
			opacity:1;
		}
	}
}
