.artist {
	$c: &;
	&-thumb {
		margin: 0 auto 33px;
		text-align: center;

		* + & {
			margin-top: 60px;
		}
		img {
			max-height: 230px;
			width: auto;
		}
	}
	&-text {
		font-size: 15px;
		line-height: (28 / 15);
		// color:#221815;
		color: #fff;
		p {
			margin-bottom: 1em;
			padding-bottom: 1em;
			border-bottom: 1px solid #dcdddd;
			font-size: 13px;
			font-weight: 400;
		}
		&-subtitle {
			font-size: 14px;
			font-weight: 700;
		}

		&-history {
			margin-bottom: 1em;
			padding-bottom: 1em;
			border-bottom: 1px solid #dcdddd;
			dt {
				width: 70px;
				float: left;
				font-size: 13px;
				&.mod-full {
					width: 100%;
					float: none;
				}

				&.mod-wide {
					width: 120px;
				}
				&.mod-subtitle {
					width: 100%;
					float: none;
					margin-top: 0.5em;
					font-weight: 700;
				}
			}
			dd {
				margin-left: 70px;
				font-size: 13px;
				&.mod-wide {
					margin-left: 120px;
				}
				&.mod-subtitle {
					display: none;
				}
			}
		}
	}
	&-text-en {
		@extend .font-heebo;
		line-height: (24 / 13);
		letter-spacing: 0.025em;
		// color:#221815;
		color: #fff;
		#{$c}-text + & {
			margin-top: 4em;
		}
		p {
			margin-bottom: 1em;
			padding-bottom: 1em;
			border-bottom: 1px solid #dcdddd;
			font-size: 14px;
			font-weight: 400;
		}
		&-subtitle {
			font-size: 15px;
			font-weight: 700;
		}
		&-history {
			margin-bottom: 1em;
			padding-bottom: 1em;
			border-bottom: 1px solid #dcdddd;
			dt {
				width: 70px;
				float: left;
				font-size: 14px;
				&.mod-full {
					width: 100%;
					float: none;
				}
				&.mod-wide {
					width: 120px;
				}
				&.mod-subtitle {
					width: 100%;
					float: none;
					margin-top: 0.5em;
					font-weight: 700;
				}
			}
			dd {
				margin-left: 70px;
				font-size: 14px;
				&.mod-wide {
					margin-left: 120px;
				}
				&.mod-subtitle {
					display: none;
				}
			}
		}
	}
}
@include breakpoint(sp) {
}
