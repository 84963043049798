.grid {
	display: flex;
	align-items: flex-end;
	&.mod-align-center {
		align-items: center;
	}
	& + & {
		margin-top: 100px;
		&.mod-no-gutter {
			margin-top: 0;
		}
	}

	&-item {
		width: 50%;
		position: relative;
		$c: &;
		&.mod-text {
			padding-right: 60px;
		}
		&.mod-img {
			+ #{$c}.mod-text {
				padding-right: 0;
				padding-left: 60px;
			}
		}

		&-img {
			background: #2e2e2e;
			img {
				width: 100%;
			}
		}
	}
}

@include breakpoint(sp) {
	.grid {
		flex-direction: column;
		& + & {
			margin-top: 60px;
			&.mod-no-gutter {
				margin-top: 60px;
			}
		}

		&-item {
			width: 100%;
			$c: &;
			&.mod-text {
				padding-right: 0px;
				order: 1;
			}
			&.mod-img {
				order: 2;
				margin-top: 25px;
				+ #{$c}.mod-text {
					padding-left: 0px;
				}
			}
		}
	}
}
