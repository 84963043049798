.bg {
	&-gray{
		background: #dcdddd;
  }
  &-darkgray{
    background: #2e2e2e;
  }
}
@include breakpoint(sp) {
}
