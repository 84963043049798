.pager {
	padding:76px 20px 80px;
	display: flex;
	justify-content:space-between;
	width: (800px + 40px);
	max-width: 100%;
	margin:0 auto;
	position: relative;
	&-prev,
	&-next{
		width: 45%;
	}
	&-next{
		text-align: right;
	}
	a{
		display: inline-block;
		@extend .font-heebo;
		font-weight: 900;
		font-size: 16px;
		padding-bottom: 7px;
		position: relative;
		&:after{
			content:"";
			display:block;
			position: absolute;
			width: 0;
			bottom:0;
			left:0;
      // border-bottom: 2px solid #000;
      border-bottom: 2px solid #fff;
			transition:0s;
		}
		&:hover:after{
			animation: move_border 1 0.3s forwards;
		}
		@keyframes move_border {
		    0% { width: 0%; }
		  100% { width: 100%; }
		}
	}
}
@include breakpoint(sp) {
	.pager {
		padding:30px 20px 30px;
		align-items: center;
		&-top{
			// position: absolute;
			// bottom:30px;
			// left:50%;
			// transform:translate(-50% ,0%);
		}
		&-next,&-prev {
			width: 40%;
		}
		a {
			display: block;
			font-size: 13px;
			span {
				display: block;
			}
		}
	}
}
