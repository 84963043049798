.cont_wrapper {
	max-width:(1080px + 40px);
	margin:0 auto;
	padding:150px 20px;
	&.mod-intro{
		padding-top: 65px;
	}
	&.mod-statement{
		padding:70px 20px 95px;
		// max-width:(800px + 40px);
		max-width: (860px + 40px);
	}
	&.mod-home_row{
		// max-width:(1280px + 40px);
		// padding:90px 20px 100px;
		max-width:(1280px + 0px);
		padding:90px 0px 100px;
		overflow: hidden;
	}
	&.mod-home_artists{
		max-width:(1200px + 40px);
		padding:90px 20px 60px;
	}
	&.mod-artists{
		padding-top: 55px;
		padding-bottom: 112px;
	}
	&.mod-exhibition{
		padding-top:150px;
		padding-bottom: 90px;
	}
}
@include breakpoint(sp) {
	.cont_wrapper {
		padding:60px 20px;
		&.mod-intro{
			padding-top: 50px;
		}
		&.mod-home_row{
			padding:45px 20px;
		}
		&.mod-home_artists{
			padding:90px 20px 45px;
			margin-top: -45px;
		}
		&.mod-artists{
			padding:45px 20px;
		}
		&.mod-exhibition{
			padding:90px 20px 45px;
		}
	}
}
