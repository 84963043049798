.text {
	font-size: 13px;
	font-weight: 700;
	line-height: 2;
	letter-spacing: 0.05em;
	.title + &{
		margin-top: 1.8em;
	}
	&.mod-home{
		font-size: 15px;
		line-height: (28 / 15);
	}
}

.text-en{
	@extend .font-heebo;
	font-size: 13px;
	font-weight: 400;
	line-height: 1.9;
	letter-spacing: 0.025em;
	.title-en + &{
		margin-top: 1.8em;
	}
	.text + &{
		margin-top: 1.8em;
	}
	&.mod-home{
		font-size: 14px;
		line-height: (24/13);
	}
}
@include breakpoint(sp) {
	.text {
		.title + &{
			margin-top: 1.5em;
		}
	}

	.text-en{
		.title-en + &{
			margin-top: 1.5em;
		}
	}
}
