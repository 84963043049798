.sns_nav {
	margin-top: auto;
	ul{
		display: flex;
	}
	li{
		margin-left: 30px;
		@extend .font-heebo;
		font-weight: 900;
		font-size: 20px;
		&:first-child{
			margin-left: 0;
		}
	}
}
@include breakpoint(sp) {
	.sns_nav {
		margin:10px auto 0;
		li{
			font-size: 16px;
		}
	}
}
