// # text adjust
$-webkit-text-size-adjust: false;

// # text
$base_font_size: 13px;
//$font_size:100%;
$line_height: 1.5;
// $font_color:#000;
$font_color: #fff;
$-webkit-tap-highlight: false;

$black: #000;
$white: #fff;
$gray: #2e2e2e;

// link
// $link_color:#000;
// $link_hover_color:#000;
$link_color: #fff;
$link_hover_color: #fff;

// BREAKPOINT
// --------------------
$breakpoints: (
	exsp: 321px,
	sp: 768px,
	tb: 960px,
);

/* ===============================================
garamond typekit font
.font-garamond
or
.tk-adobe-garamond-pro
=============================================== */
