.global_header {
	width: 100%;
	&-inner {
		padding: 20px 40px 20px 20px;
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	&-logo {
		@include text_indent_none;
		// background: resolve("./build/images/logo_black.png") center no-repeat;
		// background-size: size("./build/images/logo_black.png",2);
		// width: width("./build/images/logo_black.png",2);
		// height: height("./build/images/logo_black.png",2);
		background: resolve("./build/images/logo_white.png") center no-repeat;
		background-size: size("./build/images/logo_white.png", 2);
		width: width("./build/images/logo_white.png", 2);
		height: height("./build/images/logo_white.png", 2);
		&.mod-home {
			background: resolve("./build/images/logo_white_home.png") center
				no-repeat;
			background-size: size("./build/images/logo_white_home.png", 2);
			width: width("./build/images/logo_white_home.png", 2);
			height: height("./build/images/logo_white_home.png", 2);
			margin: 20px 0 0 20px;

			display: none;
		}
	}
	&-col {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: auto;
	}
	&.mod-home {
		position: absolute;
	}
}
@include breakpoint(sp) {
	.global_header {
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		background-color: #2e2e2e;
		z-index: 9;
		&.mod-home {
			position: fixed;
			background: none;
			transition: 0.3s;
			&.is-show {
				background-color: #2e2e2e;
			}
		}
		&-inner {
			padding: 10px;
			height: 68px;
		}
		&-logo,
		&-logo.mod-home {
			width: 80px;
			// background: resolve("./build/images/logo_black.png") center no-repeat;
			// background-size: contain;
			width: width("./build/images/logo_black.png", 5);
			height: height("./build/images/logo_black.png", 5);
			background: resolve("./build/images/logo_white.png") center
				no-repeat;
			// width: width("./build/images/logo_white.png",5);
			// height: height("./build/images/logo_white.png",5);
			background-size: contain;
			margin: 0;
		}
		&-logo.mod-home.is-show {
			display: block;
		}
	}
}
