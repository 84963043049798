.btn {
	display: inline-block;
	font-size: 20px;
	@extend .font-heebo;
	font-weight: 900;
	&:after{
		content:"";
		display:block;
		border-bottom: 2px solid;
		width: 0;
		transition:0.3s;
	}
	&.is-show{
		&:after{
			width: 100%;	
		}			
	}
}
@include breakpoint(sp) {
}
