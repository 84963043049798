.home_links {
	display: flex;
	width: 100%;
	max-width: 1040px;
	margin:0 auto;
	padding:92px 0px 57px;
	&-item{
		width: 50%;
		text-align: center;
	}
	a{	
		display: inline-block;
		@extend .font-heebo;
		font-weight: 900;
		font-size: 20px;
		padding-bottom: 5px;
		position: relative;
		&:after{
			content:"";
			display:block;
			position: absolute;
			width: 0;
			bottom:0;
			left:0;
			border-bottom: 2px solid #000;
			transition:0s;
		}
		&:hover:after{
			animation: move_border 1 0.3s forwards;
		}
		@keyframes move_border {
		    0% { width: 0%; }
		  100% { width: 100%; }
		}
	}
}
@include breakpoint(sp) {
}
