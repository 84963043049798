.dl_basic {
  &.mod-short{
    display: flex;
    flex-wrap: wrap;
    dt{
      width: 6em;
    }
    dd{
      width: calc(100% - 6em);
    }
  }
  & + &{
    margin-top: 30px;
  }
}
@include breakpoint(sp) {
}
