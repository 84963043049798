.home_news {
	background: #000;
	&-inner{
		width: 1000px + 40px;
		padding:26px 20px 29px;
		margin:0 auto;
		color:#fff;
		display: flex;
		align-items:center;
	}
	&-date{
		width: 110px;
		line-height: 1.1;
	}
	&-text{
		font-size: 13px;
		flex:1;
		a{
			color:#fff;
			transition:0.2s;
			&:hover{
				color:#6f6f6f;
			}
		}
	}
	&-month{
		@extend .font-heebo;
		font-weight: 900;
		font-size: 14px;
		color:#ffffff;
		letter-spacing: 0.04em;
	}
	&-year{
		@extend .font-heebo;
		font-weight: 900;
		font-size: 	14px;
		color:#999999;
		letter-spacing: 0.04em;
	}
}
@include breakpoint(sp) {
	.home_news {
		&-inner{
			width: 100%;
			padding:26px 20px;
			margin:0 auto;
			color:#fff;
			display: flex;
			align-items:center;
		}
		&-date{
			width: 90px;
			line-height: 1.2;
		}
		&-text{
			font-size: 13px;
			flex:1;
		}
		&-month{
			font-size: 16px;
		}
		&-year{
			font-size: 16px;
		}
	}
}
