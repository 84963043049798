.lang {
	&-jp{
		font-size: 13px;
		&.mod-small{
			font-size: 12px;
			line-height: (22/12);
		}
	}

	&-en{
		font-size: 12px;
		@extend .font-heebo;
		&.mod-small{
			font-size: 11px;
			line-height: (18/11);
		}
	}
}
@include breakpoint(sp) {
}
