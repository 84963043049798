.link_more {
	text-align: right;
	margin-top: 2em;
	& + &{
		margin-top: 1em;
	}
	a{
		text-decoration: underline;
		font-weight: 700;
		@extend .font-heebo;
		&:hover{
			text-decoration: none;
		}
	}
}
@include breakpoint(sp) {
}
