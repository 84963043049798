#totop {
	display: block;
	position: fixed;
	// right:30px;
	right: 0;
	top:50%;
	transform:translate(100% ,-50%);
	transition:0.3s;
	opacity:0;
	&._active {
		opacity:1;
		right: 40px;
	}
	a{
		// background: resolve("goup.png") center no-repeat;
		// background-size: size("goup.png",2);
		// width: width("goup.png",2);
		// height: height("goup.png",2);
		// display: block;
    // @include text_indent_none;
    writing-mode: vertical-lr;
    @extend .font-heebo;
    font-size: 22px;
    font-weight: 500;
    color:#fff;
	}
}
@include breakpoint(sp) {
	#totop {
		display: none;
		right:10px;
	}
}
