.page-404 {
	.page_header {
		margin-bottom: 20px;
	}
	p.ac {
		margin-top: 20px;
	}
	.btn_list {
		margin-top: 69px;
		.btn {
			border-bottom: 2px solid;
		}
	}
}
