.title {
	font-size: 26px;
	font-weight: 700;
}
.title-en{
	font-size: 26px;
	@extend .font-heebo;
	font-weight: 500;
	letter-spacing: 0.05em;
}
@include breakpoint(sp) {
}
