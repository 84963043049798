.cont_header {
	margin:0px auto 60px;
	max-width:1080px;
	&-title{
		font-size: 50px;
		@extend .font-heebo;
		font-weight: 900;
		&.mod-center{
			text-align: center;
		}
	}
}
@include breakpoint(sp) {
	.cont_header {
		margin:0px 0 40px;
		&-title{
			font-size: 40px;
		}
	}
}
