@charset "UTF-8";
/* ===============================================
Trello CSS Guide

https://gist.github.com/bobbygrace/9e961e8982f42eb91b80
http://qiita.com/naru0504/items/5427f3795171c540ed91
=============================================== */

/* base
----------------------- */
@import "base/setting.scss";
@import "helper/mixin.scss";
@import "base/reset.scss";
@import "fonts/font.scss";

/* vendor
----------------------- */
// @import "vendor/remodal-default-theme.scss";
// @import "vendor/remodal.scss";
@import "vendor/swiper.min.scss";

/* layout
----------------------- */
@import "inc/_layout.scss";

/* component
----------------------- */
@import "inc/_component.scss";

/* page
----------------------- */
@import "inc/_page.scss";

/* page
----------------------- */
@import "helper/shorthands.scss";
//test run
@include breakpoint(sp) {
	.test {
		display: flex;
		transition: (opacty 0.6s);
		color: #000;
	}
	background: resolve("eveniser.jpg") no-repeat left top;
	background-size: size("eveniser.jpg"); //retinaは第二引数に2
	width: width("eveniser.jpg");
	height: height("eveniser.jpg");
}
