.supporters {
	display: flex;
	width: 1100px;
	margin: 0 auto;
	&-title {
		width: 140px;
		@extend .font-heebo;
		font-size: 20px;
		font-weight: 900;
	}
	&-cont {
		font-size: 10px;
		line-height: (16/10);
	}
	&-list {
		@extend .clearfix;
		padding-top: 24px;
		margin-bottom: 40px;
		display: flex;
		flex-wrap: wrap;
		li {
			// float: left;
			margin: 0 35px 0 10px;
			> * {
				display: block;
				@include text_indent_none;
			}
		}
	}
	&-text {
		word-wrap: break-word;
		white-space: normal;
		& + & {
			margin-top: 1em;
		}
	}
}
.logo_ateliers {
	@include bgset("logo_ateliers.png", 2);
}
.logo_bunka {
	@include bgset("logo_bunka.png", 2);
}
.logo_jeki {
	@include bgset("logo_jeki.png", 2);
}
.logo_kyoto {
	@include bgset("logo_kyoto.png", 2);
}
.logo_kyotoartcenter {
	@include bgset("logo_kyotoartcenter.png", 2);
}
.logo_les {
	@include bgset("logo_les.png", 2);
}
.logo_takashimaya {
	@include bgset("logo_takashimaya.png", 2);
}
.logo_tcilab {
	@include bgset("logo_tcilab.png", 2);
}
.logo_kyoto_museum {
	@include bgset("logo_kyoto_museum.png", 2);
}
@include breakpoint(sp) {
	.supporters {
		width: 100%;
		flex-direction: column;
		padding: 0 20px;
		&-title {
			margin-bottom: 20px;
		}
		&-list {
			justify-content: center;
			padding-top: 0;
			li {
				margin: 0 15px 10px;
			}
		}
	}
}
