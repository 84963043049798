#wrap{
	position: relative;
}
@include breakpoint(sp) {
	#wrap {
		// padding-top:68px;
		&.mod-home{
			// padding-top: 0;
		}
	}
}
