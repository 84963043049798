.js-gnav-slidein {
	li{
		opacity: 0;
		transform:translate(0% ,-20px);
		transition:0.3s ease-in;
		&.is-show{
			opacity: 1;
			transform:translate(0% ,0);
		}
	}
}
@include breakpoint(sp) {
	.js-gnav-slidein {
		li{
			opacity: 1;
			transform:translate(0% ,0px);
		}
	}
}
