.section {
	&.mod-supporters{
    padding:60px 0;
    background: #fff;
    color:#000;
	}
	&.has-border_b{
		border-bottom: 1px solid #dcdddd;
	}
}
@include breakpoint(sp) {
	
}
