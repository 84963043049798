.btn_list {
	display: flex;
	justify-content:center;
	> *{
		margin:0 60px;
	}
}
@include breakpoint(sp) {
	.btn_list {
		display: flex;
		justify-content:center;
		> *{
			margin:0 30px;
		}
	}
}
