.global_footer {
	background: #2e2e2e;
	color:#fdfdfd;
	a{
		color:#fdfdfd;
		text-decoration: none;
	}
	&-logo{
		width: 170px;
	}
	&-info {
		strong {
			font-size: 12px;
		}
	}
	&-inner{
		width: 100%;
		max-width: 1200px;
		padding:35px 0px 25px;
		margin :0 auto;
		display: flex;
		justify-content:space-between;
	}
	&-col{
		display: flex;
		flex-direction: column;
		justify-content:space-between;
		&:nth-of-type(2) {
			margin-left: -97px;
		}
	}
	.copyright{
		font-size: 10px;
		margin-top: 20px;
	}
	a{
		@include opacity--anim(0.6);
	}
}

@include breakpoint(sp) {
	.global_footer {
		text-align: left;
		&-col {
			&:nth-of-type(2) {
				margin-left: 0px;
			}
		}
		&-logo {
			margin-bottom: 20px;
		}
		&-inner{
			flex-direction: column;
			padding:35px 10px 25px;
		}
		&-info{
			margin-bottom: 1em;
		}
		.copyright{
			text-align: center;
			margin-bottom: 20px;
		}
	}
}
