.breadcrumbs {
	text-align: right;
	margin-bottom:20px;
	a {
		transition: all .6s;
		&:hover {
			color: #6f6f6f;
		}
	}
	ul{
		display: inline-block;
	}
	li{
		@extend .font-heebo;
		font-weight: 900;
		font-size: 13px;
		letter-spacing: 0.01em;
		display: inline-block;
		&:last-child{
			color:#6f6f6f;
		}
		+ li{
			&:before{
				content:"-";
				display: inline-block;
				margin: 0 6px 0 1px;
				color:#000;
			}
		}
	}
}
@include breakpoint(sp) {
	.breadcrumbs{
		display: none;
	}

}
