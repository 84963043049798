.global_nav {
	@extend .font-heebo;
	font-size: 26px;
	letter-spacing: 0.01em;
	font-weight: 900;
	margin-top: 3px;
	ul{
		display: flex;
	}
	li{
		margin-left: 20px;
	}
	a{
    // color:#000;
    color:#fff;
		letter-spacing: 0.01em;
		transition:0.2s;
		&:hover{
			color:#6f6f6f;
		}
	}
	&.mod-home{
		margin-top: 12px;
		a{
      // color:#fff;
      color:#000;
			transition:0.2s;
			&:hover{
				color:#6f6f6f;
			}
		}
	}
}
body.about .nav-01 a,
body.process .nav-02 a,
body.artist .nav-03 a{
	color:#999;
}
@include breakpoint(sp) {
	.global_nav {
		display: none;
	}
}
