.slide {
	.swiper-slide {
		background-color: #2e2e2e;
		display: flex;
		flex-direction: column;
		height: 100%;
		position: static;
		overflow: visible;
		opacity: 0 !important;
		&.swiper-slide-active {
			opacity: 1 !important;
		}
	}
	&-img {
		height: 530px;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		position: relative;
	}
	&-caption {
		color: #fff;
		text-align: center;
		margin-top: 10px;
		font-size: 16px;
		padding: 0 20px;
		position: relative;
	}
	.swiper-button-prev {
		background: resolve("arrow_left_white.png") center no-repeat;
		background-size: size("arrow_left_white.png", 2);
		width: width("arrow_left_white.png", 2);
		height: height("arrow_left_white.png", 2);
		top: 50%;
		left: 20px;
		margin-top: 0;
		transform: translate(0, -50%);
	}
	.swiper-button-next {
		background: resolve("arrow_right_white.png") center no-repeat;
		background-size: size("arrow_right_white.png", 2);
		width: width("arrow_right_white.png", 2);
		height: height("arrow_right_white.png", 2);
		top: 50%;
		right: 20px;
		margin-top: 0;
		transform: translate(0, -50%);
	}
	.swiper-pagination {
		line-height: 0;
		bottom: 22px;
	}
	.swiper-pagination-bullet {
		width: 40px;
		height: 4px;
		border-radius: 0;
		background: #fff;
		opacity: 1;
	}
	.swiper-pagination-bullet-active {
		background: #000;
	}
}
@include breakpoint(sp) {
	.slide {
		&-img {
			height: 250px;
		}
		&-caption {
			font-size: 14px;
		}
		.swiper-button-prev,
		.swiper-button-next {
			width: 6vw;
			background-size: contain;
		}
	}
}
