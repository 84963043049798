.home_row {
	// width: 100%;
	// max-width: 1280px;
	width: 1280px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	&-col {
		&.mod-text {
			width: 37.5%;
		}
		&.mod-img {
			width: (740/1280) * 100%;
			background: #2e2e2e;
		}

		&.mod-padding-left {
			padding-left: 100px;
		}
		&.mod-padding-right {
			padding-right: 100px;
		}
	}
}
@include breakpoint(sp) {
	.home_row {
		flex-direction: column;
		width: 100%;
		&-col {
			&.mod-text {
				width: 100%;
				order: 1;
			}
			&.mod-img {
				width: 100%;
				order: 2;
				margin-top: 20px;
			}

			&.mod-padding-left {
				padding-left: 0;
			}
			&.mod-padding-right {
				padding-right: 0;
			}
		}
	}
}
