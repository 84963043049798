.home_mv {
	width: 100%;
	height: 100vh;
	background: resolve("home_mv_2021.jpg") center no-repeat;
	//   background: url("https://placehold.jp/300/eeeeee/dddddd/2000x1600.png?text=MV%0AWhite%20based%20image") center no-repeat;
	background-size: cover;
	&-logo {
		&-img {
			@include text_indent_none;
			display: block;
			background: resolve("./build/images/logo_gray_home.png") center
				no-repeat;
			background-size: size("./build/images/logo_gray_home.png", 2);
			width: width("./build/images/logo_gray_home.png", 2);
			height: height("./build/images/logo_gray_home.png", 2);
			position: absolute;
			top: 20px;
			left: 20px;
			margin: 20px;
		}
	}
	#myCanvas {
		position: absolute;
		top: -110px;
		left: -36px;
		// width: 512px;
		// height: 512px;
	}
}
@include breakpoint(sp) {
	.home_mv {
		&-logo {
			&-img {
				display: block;
				background: resolve("./build/images/logo_gray_home.png") center
					no-repeat;
				background-size: size("./build/images/logo_gray_home.png", 4);
				width: width("./build/images/logo_gray_home.png", 4);
				height: height("./build/images/logo_gray_home.png", 4);
				position: absolute;
				top: 20px;
				left: 20px;
				margin: 0px;
			}
		}

		#myCanvas {
			top: -56px;
			left: -20px;
			// width: 260px;
			// height: 260px;
		}
	}
}
