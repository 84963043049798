.archive-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;

  ul {
    margin-left: 8px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;

    li + li {
      margin-left: 10px;
    }
  }
  a{
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
}
@include breakpoint(sp) {
}
