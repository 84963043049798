.exhibition_list {
	dt{
		float: left;
		clear:left;
		width: 200px;
	}
	dd{
		margin-left: 210px;
		margin-bottom: 2em;
	}
	.text + .text-en{
		margin-top: 0;
	}
}
@include breakpoint(sp) {
	.exhibition_list {
		dt{
			float: none;
			width: 100%;
		}
		dd{
			width: 100%;
			margin-left: 0px;
		}
		.text + .text-en{
			margin-top: 0;
		}
	}
}
