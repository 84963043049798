.splash {
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 100;
	top:0;
	left:0;
	&.is-hide{
		display: none;
	}
	>*{
		position: absolute;
		width: 50%;
		height: 33.3333%;
    // background: #000;
    background: #fff;
		// transition:0.5s ease-out;
	}
	&-01{
		top:0%;
		left:0%;
		transform:scale(1,1);
		transform-origin:0 0;
		// &.is-show{
		// 	transform:scale(0,1);
		// }
	}
	&-02{
		top:0%;
		left:50%;
		transform:scale(1,1);
		transform-origin:0 0;
		// &.is-show{
		// 	transform:scale(1,0);
		// }
	}
	&-03{
		top:33.3333%;
		left:0%;
		transform:scale(1,1);
		transform-origin:0 100%;
		// &.is-show{
		// 	transform:scale(1,0);
		// }
	}
	&-04{
		top:33.3333%;
		left:50%;
		transform:scale(1,1);
		transform-origin:100% 0;
		// &.is-show{
		// 	transform:scale(0,1);
		// }

	}
	&-05{
		top:66.6667%;
		left:0%;
		transform:scale(1,1);
		transform-origin:0 100%;
		// &.is-show{
		// 	transform:scale(1,0);
		// }
	}
	&-06{
		top:66.6667%;
		left:50%;
		transform:scale(1,1);
		transform-origin:0 0;
		// &.is-show{
		// 	transform:scale(1,0);
		// }
	}
}
@include breakpoint(sp) {
}
