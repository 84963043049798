.spnavi {
	display: none;
	li{
		@extend .font-heebo;
		font-size: 60px;
		letter-spacing: 0.01em;
		font-weight: 900;
    // color:#fff;
    color:#000;
		line-height: 1.3;
	}
}
.btn_sp_navi_wrap {
	display: none;
	a{
		display: block;
		padding:20px;
	}
	.line {
		width: 20px;
		height: 2px;
    // background-color: #000;
    background-color: #fff;
		display: block;
		margin-top: 5px;
		margin-left: auto;
		margin-right: auto;
		transition: all .3s;
		&.is-white{
      // background-color: #fff;
      background-color: #000;
		}

	}
	.active {
		.line {
			// color: #000;
			// background-color: #000;
			color:#fff;
			background-color: #fff;
			&:nth-of-type(1) {
				transform: translateY(10px) rotate(-45deg);
			}
			&:nth-of-type(2) {
				opacity: 0;
			}
			&:nth-of-type(3) {
				transform: translateY(-4px) rotate(45deg);
			}
		}
	}

}
@include breakpoint(sp) {
	.spnavi {
		position: fixed;
		width: 100%;
		height: 100%;
    // background-color: rgba(0,0,0,1);
    background: #fff;
		text-align: center;
		z-index: 10;
		padding-top: 68px;
		ul{
			margin-top: 40px;
		}
		a{
      // color:#fff;
      color:#000;
			width: 100%;
		}

	}
	.btn_sp_navi_wrap {
		display: block;
		z-index: 12;
		position: fixed;
		right: 0px;
		top: 0px;
	}
}
@include breakpoint(exsp) {
	.spnavi {
		ul{
			margin-top: 20px;
		}
		li{
			line-height: 1.2;
		}
	}	
}