.hero {
	background: #2e2e2e;
	&-img {
		height: 440px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
}
@include breakpoint(sp) {
	.hero {
		&-img {
			height: 40vw;
		}
	}
}
